<template>
    <div class="content-container">
    <div v-if="allTeamUpdates" class="container" >
            <Post v-bind:key="post.node.id" :post="post" v-for="post in allTeamUpdates.edges"></Post>

    </div>
        </div>
</template>

<script>
    import Post from "./Post";
    export default {
        name: "PostFeed",
        data: function(){
            return{allTeamUpdates: null};
        },
        components: {Post},
        apollo: {
           me: {
            query() {
                return require("../graphql/me.gql");
            }
           },
            allTeamUpdates: {
                query() {
                    return require("../graphql/allPosts.gql");
                },
                variables: function () {
                    return {
                        "teamId": this.teamID
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .container {
        font-family: "Open Sans", sans-serif;
    }

    .content-container {
        min-height: 800px;
        background-color: rgba(254, 247, 239, 0.4);
        padding-top: 20px;
        margin-top: 30px;

    }
</style>