import decode from 'jwt-decode'

const AUTH_TOKEN_KEY = 'authToken'


var ClientOAuth2 = require('client-oauth2')

var authServer = new ClientOAuth2({
  clientId: 'MDcXhdOLXr76nh1J15Ky9GiRrlXDkOTowZG5X6rL',
  clientSecret: '2Aj3SIWNNgI23XArchGzCNoRpCX8jjdjzAmOIK2uvGWhXV3xIYRYmwoD8wHMJvJIqcDGK5MGhu2XCLXT0UHsAQasRwKbgwK0ORvC3LUc1gwTLKXKq4VwQzjQCnSH14QU',
  accessTokenUri: 'https://api.quartierwir.de/id-service/oauth/token/',
  authorizationUri: 'https://api.quartierwir.de/id-service/oauth/authorize/',
  redirectUri: 'https://mein.quartierwir.de/login/done/',
  scopes: ['add-user-to-group', 'remove-user-from-group']
})


export function loginURI() {
    return authServer.token.getUri();
}

export async function loginCallback(uri) {
    await authServer.token.getToken(uri).then(function (user) {
        //console.log(user)
        setAuthToken(user.accessToken);
    }).catch(function (error) {
        console.log(error)
    });

}

export function logout() {
    clearAuthToken()
}

export function setAuthToken(token) {
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)
}

export function clearAuthToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY)
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken && !isTokenExpired(authToken)
}

export function getUserInfo() {
    if (isLoggedIn()) {
        return decode(getAuthToken())
    }
}

function getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }

    let date = new Date(0)
    date.setUTCSeconds(token.exp)

    return date
}

function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token)
    return expirationDate < new Date()
}