<template>
        <div class="team-container" v-if="team">
        <Navbar></Navbar>
            <div  class="poster"  :style="{ backgroundImage: `url(${team.poster})` }"></div>
            <div class="container">
                <b-img rounded class="icon" :src="team.icon"> </b-img>
                <h1> {{team.name}}</h1><JoinTeamButton v-if="me && team" class="join" :team="team" :isMember="isMember(team.id)"></JoinTeamButton>



            </div>
            <div class="site-nav">
             <div class="container">
                <b-nav class="team-nav">
                    <b-nav-item :to="{ name: 'TeamPosts', params: { id: this.$route.params.id }}">Neuigkeiten</b-nav-item>
                    <b-nav-item>Termine</b-nav-item>
                    <b-nav-item :to="{ name: 'MembersList', params: { id: this.$route.params.id }}" >Mitglieder</b-nav-item>
                </b-nav>

             </div>
            </div>
            <div class="content-container">
                <router-view></router-view>
            </div>


    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    import JoinTeamButton from "../components/joinTeamButton";

    export default {
        name: "Team",
        components: {JoinTeamButton, Navbar},
        data: function() {
            return {
                "post": "",
            }
        },
        methods: {
            isMember: function(teamID) {
                var my_teams = []
                for(var mt in this.me.teams.edges) {
                    my_teams.push(this.me.teams.edges[mt].node.team.id);
                }

                return my_teams.includes(teamID)
            },
        },
        apollo: {
            me: {
                query() {
                    return require("../graphql/me.gql");
                }
            },
            team: {
                query() {
                    return require("../graphql/team.gql");
                },
                variables: function () {
                    return {
                        "teamID": this.$route.params.id
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .container {
        font-family: "Open Sans", sans-serif;
    }

    .poster {
        margin-top: 30px;
        width: 100%;
        height: 300px;
        background-size: cover;
          background-position: 50% 80%;

        background-attachment: fixed;
          background-repeat: no-repeat;
    }

    .icon {


        width: 128px;

        @media all and (max-width: 575px) {
           display: block;
            margin: 0 auto;
        }
        margin-top: -64px!important;
        border: solid 2px;
    }
    
    .team-nav {
        text-align: left;
        padding: 0;
        text-align: center;
            margin: 0;
        li {
            list-style: none;
            display: inline-block;
            margin-left: 30px;
        }

        li:first-child {
            margin-left: 0;
        }
        text-decoration: none;
    }

    .join {
        @media all and (min-width: 576px) {
            margin-top: -4.5rem;
            float: right;
            display: inline-block;
         }
        @media all and (max-width: 575px) {
            text-align: center;
             display: block;
            margin-bottom: 2rem;
         }



    }
    
    h1 {
        @media all and (min-width: 576px) {

            margin-left: 148px;
            margin-top: -52px;
            margin-bottom: 2rem;
        }
        @media all and (max-width: 576px) {
            text-align: center;
            font-size: 2rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }



    .site-nav {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0;
        margin: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .content-container {
        min-height: 800px;
        background-color: rgba(254, 247, 239, 0.4);
        padding-top: 20px;
    }
</style>