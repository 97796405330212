<template>
    <div class="container">
        <div class="col-12 col-md-10 offset-md-1 members-container">
                <ul class="list-group list-group-flush" v-if="team">
                     <li  v-for="member in team.members.edges" v-bind:key="member.node.id" class="list-group-item">
                         <b-img class="profile-picture" rounded :src="member.node.user.profilePicture"></b-img>
                         {{member.node.user.firstName}} {{member.node.user.lastName}}
                     </li>
                </ul>
            </div>
    </div>
</template>

<script>
    export default {
        name: "MembersList",
        props: [
            "teamID"
        ],
        data: function(){
            var team_id;
            if(this.teamID) {
                team_id = this.teamID;
            } else {
                team_id = this.$route.params.id
            }
            return{team: null, iteamID: team_id};
        },

        apollo: {
            me: {
                query() {
                    return require("../graphql/me.gql");
                }
            },
            team: {
                query() {
                    return require("../graphql/team.gql");
                },
                variables: function () {
                    return {
                        "teamID": this.iteamID
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .profile-picture {
        width: 48px;
        margin-right: 20px;

    }

    .members-container {
        padding-top: 20px;
        padding-bottom: 20px;
         border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
         background-color: #fff;
    }

</style>