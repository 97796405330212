<template>
    <div>

        <b-card-group columns class="team-grid">
    <b-card  v-for="team in showTeams" v-bind:key="team.id" :img-src="team.poster"
             img-alt="Image" img-top>
       <template #header>
           <b-img :src="team.icon" class="team-icon" rounded ></b-img>
      <h4 @click="goToTeam(team.id)">{{team.name}}</h4>
    </template>
        <b-card-text>


        {{team.description}}
            <br>
            <br>
            <JoinTeamButton :team="team" :is-member="isMember(team.id)"></JoinTeamButton>
      </b-card-text>
    </b-card>

  </b-card-group>
    </div>
</template>

<script>
    import JoinTeamButton from "./joinTeamButton";
    export default {
        name: "TeamsList",
        components: {JoinTeamButton},
        props: ["filter"],
        data: function() {

            return {
                allTeams: [],
                showTeams: [],
                me: {}

            }
        },

        methods: {

            goToTeam: function(teamID) {
              this.$router.push({ name: 'TeamPosts', params: { id: teamID } })
            },

            isMember: function(teamID) {
                var my_teams = []
                for(var mt in this.me.teams.edges) {
                    my_teams.push(this.me.teams.edges[mt].node.team.id);
                }

                return my_teams.includes(teamID)
            },

            updateTeamsList: function () {
                var showTeams = [];
                var my_teams = []
                for(var mt in this.me.teams.edges) {
                    my_teams.push(this.me.teams.edges[mt].node.team.id);
                }
                console.log(my_teams);
                for(var t in this.allTeams.edges) {
                    if(this.filter === "my-teams") {
                        console.log(this.allTeams.edges[t].node.id);
                        if( my_teams.includes(this.allTeams.edges[t].node.id)) {
                            showTeams.push(this.allTeams.edges[t].node);
                        }
                    }

                    if(this.filter === "house-teams") {
                        if(this.allTeams.edges[t].node.house.length > 0) {
                            showTeams.push(this.allTeams.edges[t].node);
                        }
                    }

                    if(this.filter === "content-teams") {
                        if(this.allTeams.edges[t].node.house.length === 0) {
                            showTeams.push(this.allTeams.edges[t].node);
                        }
                    }
                }

                console.log(showTeams);

                this.showTeams = showTeams;

            }
        },

        watch: {
            allTeams: function() {
                this.updateTeamsList()
            },

            filter: function () {
                this.updateTeamsList()
            }



          },
        apollo: {
            allTeams: {
              query() {
                return require("../graphql/allTeams.gql");
              }
            },
            me: {
              query() {
                return require("../graphql/me.gql");
              }
            }
  }
    }
</script>

<style scoped lang="scss">
 .team-grid {
     @media all and (max-width: 920px) {
         column-count: 2;
     }


     @media all and (min-width: 921px) {
         column-count: 3;
     }


     @media all and (max-width: 576px) {
         column-count: 1;
     }

 }
    .team-icon {
        width: 96px;
        margin-top: -48px;
        border: solid 2px;
    }
    h4 {
        margin-left: 110px;
        margin-top: -42px;
        cursor: pointer;
    }

    .card {
        border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px!important;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)!important;
    }
    .card-header {
    padding-left: 1.25rem;
    padding-top: 0.75rem;
    padding-right: 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border: 0;
    }
</style>