<template>
    <div>
        <Navbar></Navbar>
        <PostFeed></PostFeed>
    </div>

</template>

<script>
    import Navbar from "../components/Navbar";
    import PostFeed from "../components/PostFeed";
    export default {
        name: "Feed",
        components: {Navbar, PostFeed},
        apollo: {
            me: {
              query() {
                return require("../graphql/me.gql");
              }
            }
  }
    }
</script>

<style scoped lang="scss">
h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

h2 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  text-transform: uppercase;
  margin-top: 50px;

  color: #ffffff;
  background-color: #111111;
  display: inline-block;
  padding: 12px 15px 13px 15px;
}

.page {
  margin: 0;
  width: 100%;
}


footer {
  height: 150px;
  margin-top: 10px;

  .logo {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
    .main-navbar {
        background-color: #fff!important;
            box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
        color: rgba(0,0,0,0.65);
        .profile-image {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            border: 1px solid rgba(255, 153, 0, 75);
        }
    }
</style>