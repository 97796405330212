<template>
    <div class="modal-container">
        <div v-if="step === 'welcome'">
             <div v-if="me" class="welcome-text">
             <b-img center rounded :src="me.profilePicture"
                        class="profile-image" alt="Profilbild">
                 </b-img><br>
                             <h3>Herzlich Willkommen {{me.firstName}}!</h3>
               <p>Cool, dass Du hier her gefunden hast! Bevor Du loslegen kannst, benötigen wir aber noch ein paar Informationen von Dir.</p>
                     <b-button variant="outline-secondary" @click="toHouseChooser">Weiter</b-button>
           </div>
        </div>
        <div v-if="step === 'houseChooser'">
             <div v-if="me" class="welcome-text">
                 <h3>Dein Haus</h3>
               <p>Bitte wähle aus, in welchem Haus Du momentan primär lebst oder arbeitest:</p>
                 <div  v-on:click="selectHouse(house)" class="house" v-for="house in getAvailableHouses" :key="house.id" >
                     {{house.name}}
                 </div>

           </div>
        </div>
        <div v-if="step === 'poBox'">
             <div v-if="me" class="welcome-text">
                 <h3>Dein Briefkasten</h3>
                 <p>Bitte gib hier den Namen auf Deinem Briefkasten <b>im QuartierWir</b>    ein:</p>
                     <b-form-input v-model="postBoxName" placeholder="z.B. MÜLLER/MAIER"></b-form-input>
                 <br>
                  <b-button variant="outline-secondary" @click="toNewsletter">Weiter</b-button>
           </div>
        </div>
        <div v-if="step === 'newsletter'">
             <div v-if="me" class="welcome-text">
                 <h3>Newsletter</h3>
                 <p>Dürfen wir dir einmal die Woche eine Zusammenfassung der Aktivitäten im Quartier per E-Mail schicken?</p>

                  <b-button variant="outline-secondary"  @click="toDone(true)">Ja</b-button>
                  <b-button variant="outline-secondary" @click="toDone(false)" style="margin-left: 10px;">Nein</b-button>
           </div>
        </div>
        <div v-if="step === 'done'">
             <div v-if="me" class="welcome-text">
                 <h3>Dankeschön!</h3>
                 <p>Das wars schon. Viel Spaß bei der Mitarbeit in den AGs!</p>
                  <b-button variant="outline-secondary" @click="done()" style="margin-left: 10px;">Fertig</b-button>
           </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AccountSetup",
        data: function () {
            return {
                step: 'welcome',
                house: null,
                postBoxName: "",
                newsletter: null

            }
        },
         apollo: {
            me: {
              query() {
                return require("../graphql/me.gql");
              }
            },
             getAvailableHouses: {
                query() {
                    return require("../graphql/availableHouses.gql");
                  }
             }

            },
        methods: {
            toHouseChooser: function () {
                this.step = "houseChooser";
            },
            selectHouse: function (house) {
                this.house = house;
                if(house.external === false)
                {
                    this.step = "poBox";
                } else {
                    this.step = "newsletter";
                }


            },
            toNewsletter: function () {
                this.step = "newsletter";
            },
            toDone: function (newsletter) {
                this.newsletter = newsletter;
                if(this.postBoxName === "") {
                    this.postBoxName = "unknown";
                }
                this.$apollo.mutate({
                  // Query
                  mutation: require("../graphql/updateProfile.gql"),
                  // Parameters
                  variables: {
                    house: this.house.id,
                    newsletter: this.newsletter,
                    nameOnPoBox: this.postBoxName,
                    language: "de",
                  },});

                this.$apollo.mutate({
                  // Query
                  mutation: require("../graphql/joinTeam.gql"),
                  // Parameters
                  variables: {
                   teamId: this.house.team.id
                  },});
                this.step = "done";
            },
            done: function () {
                this.$router.go("Teams")
            }
        }
    }
</script>

<style scoped  lang="scss">

    .modal-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
.profile-image {
    margin-top: 20px;
    width: 100px;
    height: 100px;
    border: 2px solid rgba(255, 153, 0, 75);
}
h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
.welcome-text {
    text-align: center;

    p {
        font-family: "Bitter", sans-serif;
    }
}

    .house {
        width: 40%;
        display: inline-block;
        border: 5px solid rgba(255, 153, 0, 75);
        border-radius: 4px;
        margin: 2%;
        height: 100px;
        line-height: 90px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 32pt;
        color: rgba(255, 153, 0, 75);
        cursor: pointer;
    }

    .house:hover {
        border: 5px solid rgba(255, 153, 0, 75);
        background-color: rgba(255, 153, 0, 75);

        color: #fff;
        cursor: pointer;
    }

</style>