<template>
    <span>
     <b-button variant="outline-secondary" v-if="isMember === false"  @click="join()">
         Beitreten
     </b-button>
     <b-button class="membership-btn" v-if="isMember === true"  @click="leave()">
         <span><b-icon icon="check" aria-hidden="true"></b-icon> Mitglied</span>
     </b-button>
        </span>
</template>

<script>
    export default {
        name: "joinTeamButton",
        props: ["team", "isMember"],
        methods: {
            join: function () {
                this.$apollo.mutate({
                  // Query
                  mutation: require("../graphql/joinTeam.gql"),
                  // Parameters
                  variables: {
                   teamId: this.team.id
                  },});
                this.isMember = true;
            },
            leave: function () {
                this.$apollo.mutate({
                  // Query
                  mutation: require("../graphql/leaveTeam.gql"),
                  // Parameters
                  variables: {
                   teamId: this.team.id
                  },});
                this.isMember = false;
            },
        }
    }
</script>

<style scoped lang="scss">
    .membership-btn {
        border-color: rgba(255, 153, 0, 1);
        background-color: rgba(255, 153, 0, 1);
        width: 7rem;
        font-family: "Open Sans", sans-serif;


    }
    .membership-btn:hover {

        border-color: rgba(255, 153, 0, 0.75);
        background-color: rgba(255, 153, 0, 0.75);
        content: "Austreten";
        font-weight: 600;
    }

    .membership-btn:hover span {display:none}
    .membership-btn:hover:before {content:"Austreten!"}



</style>