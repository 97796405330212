<template>
    <div class="teams-container">
        <Navbar></Navbar>
        <div class="row">
            <div class="col-lg-9 col-md-12 col-sm-12 order-md-2 order-sm-2 order-xs-2 order-lg-1">

            <TeamsList :filter="filterChoice" ></TeamsList>
        </div>
        <div class="col-lg-3 col-md-12 order-md-1 order-sm-1 order-xs-1 order-lg-2 sidebar">
            <b-list-group>
              <b-list-group-item @click="setFilterChoice('my-teams')" :active="filterChoice === 'my-teams'">meine AGs</b-list-group-item>
              <b-list-group-item @click="setFilterChoice('content-teams')" :active="filterChoice === 'content-teams'">Themen AGs</b-list-group-item>
              <b-list-group-item @click="setFilterChoice('house-teams')" :active="filterChoice === 'house-teams'">Haus AGs</b-list-group-item>
            </b-list-group>
        </div>
        </div>

    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    import TeamsList from "../components/TeamsList";
    export default {
        name: "Teams",
        components: {TeamsList, Navbar},
        data: function () {
            return {
                filterChoice: "my-teams"
            }

        },

        methods: {
            setFilterChoice: function (value) {
                this.filterChoice =value;
            }
        }

    }
</script>

<style scoped lang="scss">
    .teams-container {
        padding: 20px;
        padding-top: 90px;
        background-color: rgba(254, 247, 239, 0.4);


        .sidebar {
            margin-bottom: 30px;
        }

    }

    .list-group {
        font-family: "Open Sans", sans-serif;
        .list-group-item {
            cursor: pointer;

        }

        .active {
                background-color: rgba(255, 153, 0, 1);
                border-color: rgba(255, 153, 0, 1);
            font-weight: 600;

        }
    }



</style>