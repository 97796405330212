<template>
     <div class="container " v-if="allTeamUpdates">

        <div class="col-md-12 col-lg-10 offset-lg-1 editor-container" v-if="me">
            <div class="row">
                <div class="col-md-1 d-none d-md-inline-block"><b-img class="profile-picture" rounded :src="me.profilePicture"></b-img></div>
                 <div class="col-9 col-lg-10">
                      <Editor ref="editor"></Editor>
                 </div>
                <div class="col-lg-1 col-3"><b-btn @click="sendPost" alt="Absenden" class="send-button"><b-icon icon="chevron-right"></b-icon></b-btn></div>
            </div>
        </div>
        <div class="col-md-12 col-lg-10 offset-lg-1 editor-container" v-for="post in allTeamUpdates.edges" v-bind:key="post.node.id">
            <div class="row">

                <div class=" d-inline-block col-lg-1 d-inline-block col-md-1 col-sm-2 col-2"><b-img class="profile-picture" rounded :src="post.node.createdBy.profilePicture"></b-img></div>
                 <div class=" d-inline-block col-md-9 col-sm-10 col-lg-11 col-10">
                     <b>{{post.node.createdBy.firstName}} {{post.node.createdBy.lastName}}</b>
                     <small> • {{post.node.createdAt}}</small>
                     <p v-html="post.node.text"></p>

                 </div>
                <div class="post-btn" v-if="post.node.createdBy.id == me.id">
                    <b-btn @click="sendPost" alt="Bearbeiten" class="btn-sm edit-button"><b-icon icon="pencil"></b-icon></b-btn>
                    <b-btn @click="deletePost(post.node.id)" alt="Löschen" class="btn-sm delete-button"><b-icon icon="trash"></b-icon></b-btn>
                </div>

            </div>
        </div>

    </div>

</template>

<script>
        import Editor from "../components/Editor";

    export default {
        props: [
            "teamID"
        ],
        name: "TeamPosts",
        components: {Editor},
        data: function(){
            var team_id;
            if(this.teamID) {
                team_id = this.teamID;
            } else {
                team_id = this.$route.params.id
            }
            return{allTeamUpdates: null, iteamID: team_id};
        },
        methods: {
          sendPost: function () {
               this.$apollo.mutate({
                  // Query
                  mutation: require("../graphql/createPost.gql"),
                  // Parameters
                  variables: {
                      teamId: this.iteamID,
                      text: this.$refs.editor.editor.getHTML()
                  },}).then((data) => {
                      console.log(data);
                      this.$apollo.queries.allTeamUpdates.refetch();
                      this.$refs.editor.clear();
                   this.$bvToast.toast(`Danke für Deinen Beitrag!`, {
                       title: `Versendet`,
                      toaster: "b-toaster-top-right",
                      solid: true,
                    });

                }).catch((error) => {
                  // Error https://github.com/apollographql/apollo-feature-requests/issues/46#issuecomment-498423148
                   this.$bvToast.toast(`${error.message .replace('GraphQL error:', '')}`, {
                      title: `Uups, das hat nicht geklappt!`,
                      toaster: "b-toaster-top-right",
                      solid: true,
                    });
                  // We restore the initial user input

                });
          },
          deletePost: function (postId) {
               this.$apollo.mutate({
                  // Query
                  mutation: require("../graphql/deletePost.gql"),
                  // Parameters
                  variables: {
                      postId: postId,
                  },}).then((data) => {
                      console.log(data);
                      this.$apollo.queries.allTeamUpdates.refetch();
                   this.$bvToast.toast(`Dein Beitrag wurde gelöscht.`, {
                       title: `Gelöscht`,
                      toaster: "b-toaster-top-right",
                      solid: true,
                    });

                }).catch((error) => {
                  // Error https://github.com/apollographql/apollo-feature-requests/issues/46#issuecomment-498423148
                   this.$bvToast.toast(`${error.message .replace('GraphQL error:', '')}`, {
                      title: `Uups, das hat nicht geklappt!`,
                      toaster: "b-toaster-top-right",
                      solid: true,
                    });
                  // We restore the initial user input

                });
          },

        },
        apollo: {
           me: {
            query() {
                return require("../graphql/me.gql");
            }
           },
            allTeamUpdates: {
                query() {
                    return require("../graphql/postsByTeam.gql");
                },
                variables: function () {

                    return {
                        "teamId": this.iteamID
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    .editor-container {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 30px;
        border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
         background-color: #fff;


    }

    .btn-sm {
        float: right;
        border-color: rgba(255, 153, 0, 1);
        background-color: rgba(255, 153, 0, 1);
        margin-left: 5px;

    }
    .btn-sm:hover, .btn-sm:focus, .btn-sm:active {

        border-color: rgba(255, 153, 0, 0.75);
        background-color: rgba(255, 153, 0, 0.75);
        font-weight: 600;
    }

    .send-button {
              margin-bottom: 5px;
              bottom: 0;
              float: right;
              position: absolute;
         border-color: rgba(255, 153, 0, 1);
        background-color: rgba(255, 153, 0, 1);
        font-family: "Open Sans", sans-serif;

    }
    .send-button:hover,  .send-button:focus, .send-button:active {

        border-color: rgba(255, 153, 0, 0.75);
        background-color: rgba(255, 153, 0, 0.75);
        font-weight: 600;
    }
    .profile-picture {
        width: 48px;
        margin-right: 20px;

    }

    .post-btn {
        float: right;
        width: 100%;
        margin-right: 15px;
        margin-top: -32px;
          @media all and (min-width: 576px) {
              display: none;
          }
        z-index: 100;
    }
    
    .editor-container:hover .post-btn {

        display: block;
    }

</style>