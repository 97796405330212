<template>
    <div class="container">
         <div class="col-12 col-md-10 offset-md-1 members-container">
             <h1>404 - Nicht gefunden</h1>
             <p>🤖 Bleep Bleep</p>
         </div>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>

</style>