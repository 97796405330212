import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
import * as AUTH from "../../auth.js"
import Feed from "../views/Feed";
import Teams from "../views/Teams";
import Team from "../views/Team";
import TeamPosts from "../components/TeamPosts";
import MembersList from "../components/MembersList";
import NotFound from "../views/NotFound";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
     beforeEnter(to, from, next) {
        if(AUTH.isLoggedIn() === false) {
          window.location = AUTH.loginURI();
          next();
        } else {
          next({
            name: "Feed" // back to safety route //
          });
        }
      }
  },
  {
    path: "/feed",
    name: "Feed",
    component: Feed,
     beforeEnter(to, from, next) {
        if(AUTH.isLoggedIn() === true) {
          next();
        } else {
          next({
            name: "Home" // back to safety route //
          });
        }
      }
  },
  {
    path: "/teams",
    name: "Teams",
    component: Teams,
     beforeEnter(to, from, next) {
        if(AUTH.isLoggedIn() === true) {
          next();
        } else {
          next({
            name: "Home" // back to safety route //
          });
        }
      }
  },
  {
    path: "/team/:id",
    name: "Team",
    component: Team,
     beforeEnter(to, from, next) {
        if(AUTH.isLoggedIn() === true) {
          next();
        } else {
          next({
            name: "Home" // back to safety route //
          });
        }
      },

      children: [
      // Note we provide the above parent route name on the default child tab
      // route to ensure this tab is rendered by default when using named routes
      { path: '', component: TeamPosts, name: 'TeamPosts' },
      { path: 'members', component: MembersList, name: 'MembersList' },
    ]
  },
      { path: '/login/done',
        beforeEnter: async function(to, from, next) {
        console.log(to);
        await AUTH.loginCallback(window.location).then(function () {
           next("Feed")
        }).catch(function () {
          next("Home")
        });

    }},
    {
      // path: "*",
      path: "/:catchAll(.*)",
      name: "NotFound",
      component:NotFound,
    }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
