<template>
    <div>
    <b-navbar toggleable="lg" type="light" variant="light" class="main-navbar fixed-top">
    <b-navbar-brand href="#">
        <img style="height: 30px;" src="../assets/quartierWIR.png" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :active='$route.name =="Feed"' to="/Feed">Neuigkeiten</b-nav-item>
        <b-nav-item href="#">Termine</b-nav-item>
        <b-nav-item :active='$route.name =="Teams"' to="/Teams">AGs</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->

      <b-navbar-nav class="ml-auto" >
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content > <span v-if="me">
             <b-img rounded="true" :src="me.profilePicture"
                    class="d-inline-block align-top profile-image img-circle" alt="Profilbild">
             </b-img>
               <b>{{me.firstName}} {{me.lastName}}</b></span>
          </template>
          <b-dropdown-item href="#">Profil</b-dropdown-item>
          <b-dropdown-item href="#">Abmelden</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

    </b-collapse>
    </b-navbar>
    <b-modal id="modal-setup" hide-footer hide-header no-close-on-esc no-close-on-backdrop>
        <AccountSetup></AccountSetup>

  </b-modal>
    </div>
</template>

<script>
    import AccountSetup from "./AccountSetup";
    export default {
        name: "Navbar",
        components: {AccountSetup},
        watch: {
            me: function(me) {
              if(me.profileSetupDone === false)
              {
                  console.log("Lets setup your profile!");
                  this.$bvModal.show("modal-setup");
              }

            }
          },
         apollo: {
            me: {
              query() {
                return require("../graphql/me.gql");
              }
            },
  }
    }
</script>

<style scoped lang="scss">
    .main-navbar {
        background-color: #fff!important;
            box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
        color: rgba(0,0,0,0.65);
        .profile-image {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            border: 1px solid rgba(255, 153, 0, 75);
        }
    }

</style>